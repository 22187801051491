import { IExamHisotryLines } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from '../BaseService';

class ExamHistoryLineService extends BaseService<IExamHisotryLines> {
  constructor() {
    super('exam-history-lines');
  }

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const examHistoryLineService = new ExamHistoryLineService();
