import { CardContent, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import SectionTitle from 'components/common/SectionTitle';
import { MutableRefObject } from 'react';
import { BaseService } from 'services/api/BaseService';
import RefreshIcon from '@mui/icons-material/Refresh';

interface IHistoryLogsParams {
  service: BaseService<any>;
  columns: GridColDef[];
  outerParams: any;
  refresh: MutableRefObject<any>;
  manualRefresh?: () => void;
  // dictParams: any;
}

export const HistoryLineList = (params: IHistoryLogsParams) => {
  const { service, columns, outerParams, refresh, manualRefresh } = params;
  const { t } = useTranslation();

  return (
    <>
      <div>
        <CardContent>
          <div className="flex justify-between items-center">
            {/* Titolo della sezione */}
            <SectionTitle>{t('global.history-update')}</SectionTitle>

            {/* Bottone di refresh in alto a destra */}
            {manualRefresh && (
              <Tooltip title={t('global.refresh-table')}>
                <IconButton onClick={manualRefresh} color="primary" size="large">
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <br />
          {/* Display the grid with the history */}
          <EnhancedDataGrid
            columns={columns}
            service={service}
            outerParams={outerParams}
            refresh={refresh}
            disableDelete
          />
        </CardContent>
      </div>
    </>
  );
};
