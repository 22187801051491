const API_URL = 'http://localhost:9100/';
const SIGNATURE = '__GLM__';
const SIGNATURE_BARCODE = '_GLM_';

export async function printLabels(
  barCode,
  batch,
  expiration,
  quantity = 1,
  description,
  isBarcode = false
) {
  for (let i = 0; i < quantity; i++) {
    printLabel(barCode, batch, expiration, description, isBarcode);
  }
}

export async function printLabel(code, batch, expiration, description, isBarcode = false) {
  let expiration_date = 'N.D.',
    expiration_date_GS1 = '';

  if (!!expiration && typeof expiration === 'string' && expiration.includes('-')) {
    const splitted_date = expiration.split('-');
    splitted_date[0] = splitted_date[0].slice(2);
    expiration_date = splitted_date.join('');
    expiration_date_GS1 = `17${expiration_date}`;
  }

  const batchToPrint = batch ?? 'NO_BATCH';

  // 15 LETTERE PER SPLIT: "D. GRAM STAIN S"
  // TAKING INTO account length === 15 ==== "L. NO_BATCH NO_BATCH"
  // 15 LETTERE PER SPLIT: "                 GRAM STAIN SSSS"
  const MAX_LINE_LENGTH = 15;

  let zplString = '';
  const productIdentifier = `${SIGNATURE}${code.replace('_', '__')}${SIGNATURE}`;
  if (!isBarcode) {
    zplString =
      `^XA
          ^BY5,2,270
          ^FO 50, 50^BX,9,200,0,0,1,_,1^FD${productIdentifier}${expiration_date_GS1}10${batchToPrint.replace(
        '_',
        '__'
      )}^FS9100 ^CFA,20  ` +
      `^FO 280, 60 ^A 1, 500 ^FD ${code} ^FS  ` +
      `^FO 280, 100 ^A 1, 500 ^FD ${description.substr(
        0,
        description.length <= MAX_LINE_LENGTH ? description.length : MAX_LINE_LENGTH
      )} ^FS  ` +
      (description.length > MAX_LINE_LENGTH
        ? description.substr(MAX_LINE_LENGTH, description.length).length <= MAX_LINE_LENGTH
          ? `^FO 280, 120 ^A 1, 500 ^FD ${description.substring(
              MAX_LINE_LENGTH,
              description.length
            )} ^FS  `
          : `^FO 280, 120 ^A 1, 500 ^FD ${description.substring(
              MAX_LINE_LENGTH,
              MAX_LINE_LENGTH * 2
            )} ^FS  `
        : ' ') +
      `^FO 280, 160 ^A 1, 500 ^FD L. ${batchToPrint} ^FS  ` +
      (expiration ? `^FO 280, 200 ^A 1, 500 ^FD S. ${expiration} ^FS  ` : ' ') +
      `^XZ`;
  } else {
    // BARCODE
    // const productIdentifier = `${SIGNATURE_BARCODE}${code.replace('_', '__')}${SIGNATURE_BARCODE}`;
    // zplString = `^XA
    // ^BY1,2,100
    // ^FO50,50^BCN,N,150,Y,N^FD${productIdentifier}${expiration_date_GS1}10${batchToPrint.replace(
    //   '_',
    //   '__'
    // )}^FS
    // ^FO50,170^FD${code} - ${description.substr(
    //   0,
    //   description.length <= MAX_LINE_LENGTH ? description.length : MAX_LINE_LENGTH
    // )}^FS
    // ^FO50,200^FDL. ${batchToPrint} S. ${expiration ? `${expiration}` : ''}^FS
    // ^XZ`;
    // REDUCED QR-CODE FROM BX,9,... TO BX,3,...
    zplString = `^XA
        ^BY5,2,270
        ^FO 50, 50^BX,3,200,0,0,1,_,1^FD${productIdentifier}${expiration_date_GS1}10${batchToPrint.replace(
      '_',
      '__'
    )}^FS9100 ^CFA,20 
    ^FO50,125^FD${code} - ${description.substr(
      0,
      description.length <= MAX_LINE_LENGTH ? description.length : MAX_LINE_LENGTH
    )}^FS
    ^FO50,150^FDL. ${batchToPrint} S. ${expiration ? `${expiration}` : ''}^FS
    ^XZ`;
  }

  try {
    // Create a new instance of the object
    const browserPrint = new ZebraBrowserPrintWrapper();
    // Select default printer
    const defaultPrinter = await browserPrint.getDefaultPrinter();
    // Set the printer
    await browserPrint.setPrinter(defaultPrinter);
    // Check printer status
    let printerStatus = await browserPrint.checkPrinterStatus();
    // Check if the printer is ready
    let retry = 0;
    while (retry !== 3) {
      if (printerStatus.isReadyToPrint || printerStatus.errors === 'Error: Unknown Error') {
        try {
          await browserPrint.print(zplString);
        } catch {
          return false;
        }
        break;
      } else {
        printerStatus = await browserPrint.checkPrinterStatus();
        sleep(500);
        retry += 1;
      }
    }
    if (retry === 3) {
      alert(
        'Ci sono problemi con la stampante, si prega di ritentare. Se persiste contattare AIDIA.'
      );
      return false;
    }
  } catch (error) {
    alert("C'è stato un errore, si prega di ritentare. Se persiste contattare AIDIA.");
    return false;
  }
  return true;
}

export default class ZebraBrowserPrintWrapper {
  device = {};

  getAvailablePrinters = async () => {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8'
      }
    };

    const endpoint = API_URL + 'available';

    try {
      const res = await fetch(endpoint, config);

      const data = await res.json();

      if (
        data &&
        data !== undefined &&
        data.printer &&
        data.printer !== undefined &&
        data.printer.length > 0
      ) {
        return data.printer;
      }

      return new Error('No printers available');
    } catch (error) {
      throw new Error(error);
    }
  };

  getDefaultPrinter = async () => {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8'
      }
    };

    const endpoint = API_URL + 'default';

    try {
      const res = await fetch(endpoint, config);
      const data = await res.text();

      if (
        data &&
        data !== undefined &&
        typeof data !== 'object' &&
        data.split('\n\t').length === 7
      ) {
        const deviceRaw = data.split('\n\t');

        const name = this.cleanUpString(deviceRaw[1]);
        const deviceType = this.cleanUpString(deviceRaw[2]);
        const connection = this.cleanUpString(deviceRaw[3]);
        const uid = this.cleanUpString(deviceRaw[4]);
        const provider = this.cleanUpString(deviceRaw[5]);
        const manufacturer = this.cleanUpString(deviceRaw[6]);

        return {
          connection,
          deviceType,
          manufacturer,
          name,
          provider,
          uid,
          version: 0
        };
      }

      throw new Error("There's no default printer");
    } catch (error) {
      throw new Error(error);
    }
  };

  setPrinter = (device) => {
    this.device = device;
  };

  getPrinter = () => {
    return this.device;
  };

  cleanUpString = (str) => {
    const arr = str.split(':');
    const result = arr.slice(1).join(':').trim();
    return result;
  };

  checkPrinterStatus = async () => {
    await this.write('~hs');
    const result = await this.read();

    const errors = [];
    let isReadyToPrint = false;

    const isError = result.charAt(70);
    const media = result.charAt(88);
    const head = result.charAt(87);
    const pause = result.charAt(84);

    isReadyToPrint = isError === '0';

    switch (media) {
      case '1':
        errors.push('Paper out');
        break;
      case '2':
        errors.push('Ribbon Out');
        break;
      case '4':
        errors.push('Media Door Open');
        break;
      case '8':
        errors.push('Cutter Fault');
        break;
      default:
        break;
    }

    switch (head) {
      case '1':
        errors.push('Printhead Overheating');
        break;
      case '2':
        errors.push('Motor Overheating');
        break;
      case '4':
        errors.push('Printhead Fault');
        break;
      case '8':
        errors.push('Incorrect Printhead');
        break;
      default:
        break;
    }

    if (pause === '1') errors.push('Printer Paused');

    if (!isReadyToPrint && errors.length === 0) errors.push('Error: Unknown Error');

    return {
      isReadyToPrint,
      errors: errors.join()
    };
  };

  write = async (data) => {
    try {
      const endpoint = API_URL + 'write';

      const myData = {
        device: this.device,
        data
      };

      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: JSON.stringify(myData)
      };

      await fetch(endpoint, config);
    } catch (error) {
      throw new Error(error);
    }
  };

  read = async () => {
    try {
      const endpoint = API_URL + 'read';

      const myData = {
        device: this.device
      };

      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: JSON.stringify(myData)
      };

      const res = await fetch(endpoint, config);
      const data = await res.text();
      return data;
    } catch (error) {
      throw new Error(error);
    }
  };

  print = async (text) => {
    try {
      await this.write(text);
    } catch (error) {
      throw new Error(error);
    }
  };
}
/**
 * Delay for a number of milliseconds
 */
function sleep(delay) {
  const start = new Date().getTime();
  while (new Date().getTime() < start + delay);
}
