import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import DataGridCell from 'components/common/DataGridCell';
import { currencyFormatter } from 'helpers/currency-formatter';
import { dateTimeCell } from '../cells/date-time-cell';
import DateTimeUpdate from 'components/common/HistoryList/handleDateTimeUpdate';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';
import dayjs from 'dayjs';

export const ExamHistoryLineColumns = (
  handleDateTimeUpdate: any,
  handleDelete: any,
  hideFields: boolean
): GridColDef[] => {
  const translationPrefix = 'entities.exam';

  const { t } = useTranslation();
  const columns: GridColumns = [];

  columns.push(
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [];
        actions.push(
          <DateTimeUpdate key={980} id={params.row['id']} handleUpdate={handleDateTimeUpdate} />
        );
        actions.push(
          <ConfirmationDeleteTableDialog handleDelete={handleDelete} id={params.row.id} />
        );

        return actions;
      }
    },
    {
      field: 'exam',
      headerName: t(`${translationPrefix}.history.exam`),
      width: 100,
      filterOperators,
      editable: false,
      renderCell: baseCell
    },
    {
      field: 'datetime',
      headerName: t(`${translationPrefix}.history.datetime`),
      width: 150,
      filterOperators,
      renderCell: dateTimeCell,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('YYYY-MM-DD HH:MM:00') : '';
      }
    },
    {
      field: 'category',
      headerName: t(`${translationPrefix}.category`),
      width: 100,
      filterOperators,
      sortable: false,
      renderCell: baseCell
    },
    {
      field: 'regional_rate_snapshot_code',
      headerName: t(`${translationPrefix}.regional_rate_snapshot_code`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    }
  );

  [
    'regional_rate_snapshot_rate',
    'contractual_rate',
    'rate_1',
    'rate_2',
    'rate_3',
    'rate_4',
    'rate_5'
  ].forEach((field) => {
    columns.push({
      field: field,
      width: 150,
      headerName: t(`${translationPrefix}.${field}`),
      filterOperators: numberFilterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const price = params.value;
        return <DataGridCell content={currencyFormatter(price)} />;
      }
    });
  });

  ['extra_1', 'extra_2', 'extra_3', 'extra_4', 'extra_5'].forEach((field) => {
    columns.push({
      field: field,
      width: 200,
      headerName: t(`${translationPrefix}.${field}`),
      sortable: false,
      filterable: false,
      renderCell: baseCell
    });
  });

  if (!hideFields) {
    [
      'products_snapshot',
      'equipments_snapshot',
      'workstations_snapshot',
      'tags_snapshot',
      'macro_exams_snapshot'
    ].forEach((field) => {
      columns.push({
        field: field,
        width: 150,
        headerName: t(`${translationPrefix}.history.${field}`),
        sortable: false,
        valueFormatter: ({ value }) => {
          const values = value.map((value) => value.code);
          return values.join(', ');
        },
        filterOperators: filterOperators,
        renderCell: (params: GridRenderCellParams) => {
          const value = params.value.map((value) => value['code']);
          return <DataGridCell content={value ? value.join(',') : ''} />;
        }
      });
    });
  }

  return columns;
};
