import { IconButton } from '@mui/material';
import { ExamsColumns } from 'components/common/enhanced/common-headers';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { examService } from 'services/api';
import ProductToCostDialog from './ProductToCostDialog';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { HistoryLineList } from 'components/common/HistoryList/list/HisotryLineList';
import { examHistoryLineService } from 'services/api/history/ExamHistoryService';
import { ExamHistoryLineColumns } from 'components/common/enhanced/common-headers/exam-history-lines';
import toast from 'features/toast';

interface ProductExamsProps {
  productId: string | number;
}

function ProductExams(props: ProductExamsProps) {
  const { t } = useTranslation();
  const { productId } = props;

  const datagridRefresh = useRef(null);
  const [mode, setMode] = useState('');

  const reloadExams = () => {
    if (datagridRefresh) datagridRefresh.current();
  };

  const getExams = (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    return examService.getAllPaginated(limit, offset, { ...params, product: productId });
  };

  const columns = ExamsColumns(+productId, datagridRefresh);

  // handle the history of the product throught exams
  const historyRefresher = useRef(null);

  const handleDateTimeUpdate = (id: number, datetime: string) => {
    examHistoryLineService.update(id, { datetime: datetime }).then(() => {
      toast.success(t('actions.successfully-updated'));
      if (historyRefresher.current) {
        historyRefresher.current(); // ✅ Assicura che venga chiamata solo se assegnata
      }
    });
  };

  const handleDeleteLine = (id: number) => {
    examHistoryLineService.delete(id).then(() => {
      toast.success(t('actions.successfully-deleted'));
      if (historyRefresher.current) {
        historyRefresher.current(); // ✅ Assicura che venga chiamata solo se assegnata
      }
    });
  };

  const manualRefresh = () => {
    if (historyRefresher.current) {
      historyRefresher.current(); // ✅ Chiama la funzione di refresh se esiste
    }
  };

  const revertedColumnsExams = ExamHistoryLineColumns(handleDateTimeUpdate, handleDeleteLine, true);

  return (
    <>
      <b className="text-primary-600">{t('menu.exams')}</b>
      <IconButton color="primary" onClick={() => setMode('exams')}>
        <AddCircleOutlineIcon />
      </IconButton>
      <b className="text-primary-600">{t('menu.macro-exams')}</b>
      <IconButton color="primary" onClick={() => setMode('macroexams')}>
        <AddCircleOutlineIcon />
      </IconButton>
      <br />
      <EnhancedDataGrid
        getMethod={getExams}
        service={examService}
        columns={columns}
        refresh={datagridRefresh}
      />
      <br />
      <ProductToCostDialog
        productId={+productId}
        mode={mode}
        setMode={setMode}
        callback={reloadExams}
      />
      <br />
      <HistoryLineList
        service={examHistoryLineService}
        columns={revertedColumnsExams}
        outerParams={{ product_id: productId }}
        refresh={historyRefresher}
      />
    </>
  );
}

export default ProductExams;
