import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText,
  IconButton
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { DateTimePickerAdapter } from 'components/form/DateTimePickerAdapter';
import CloseIcon from '@mui/icons-material/Close';

interface UpdateDateTimeDialogProps {
  id: number;
  // handleOnClose?: () => void;
  handleUpdate?: (id: number, datetime: string) => void;
}

export default function DateTimeUpdate(params: UpdateDateTimeDialogProps) {
  const { id, handleUpdate } = params;
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  /**
   * Opens the confirmation dialog for deleting a row.
   *
   * @param {number} id - The ID of the row to be deleted.
   */
  const handleOpenDialog = () => {
    // Set the selected row ID and open the dialog.
    setOpenDialog(true);
  };

  /**
   * Closes the confirmation dialog.
   */
  const handleCloseDialog = () => {
    // Close the dialog and reset the selected row ID.
    setOpenDialog(false);
  };

  const handleFromUpdate = (values) => {
    handleUpdate(id, values.datetime);
    // Close the dialog and reset the selected row ID.
    setOpenDialog(false);
  };

  return (
    <>
      <GridActionsCellItem
        key={990}
        label={t('actions.update')}
        icon={<EditCalendarIcon color="action" />}
        onClick={() => handleOpenDialog()}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        {/* Intestazione con titolo e bottone di chiusura */}
        <DialogTitle>
          <div className="flex justify-between items-center">
            <span className="text">{t('global.history-update-title')}</span>
            <IconButton onClick={handleCloseDialog} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        {/* Contenuto del dialogo */}
        <DialogContent>
          <p className="text-lg">{t('global.history-update-message')}</p> {/* Font più grande */}
          <ReactFinalForm
            onSubmit={handleFromUpdate}
            formId="updateHisotryLine"
            hideControls={true}>
            <div className="flex gap-4 mt-4">
              <div className="w-1/2">
                <IdentityField
                  name="datetime"
                  component={DateTimePickerAdapter}
                  label={t('global.datetime')}
                  type="text"
                />
              </div>
              <div className="w-1/2 h-full flex">
                <Button
                  type="submit"
                  form="updateHisotryLine"
                  variant="contained"
                  className="w-full">
                  {t('actions.confirm')}
                </Button>
              </div>
            </div>
          </ReactFinalForm>
        </DialogContent>

        {/* Azioni con il pulsante di annullamento */}
        {/* <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('actions.cancel')}
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
