import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import { useTranslation } from 'react-i18next';
import { printLabels } from 'services/zebra';
import { dateFilterOperators, filterOperators, numberFilterOperators } from '../../../../constants';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { baseCell } from '../cells/base-cell';
import productStatusCell from '../cells/product-status-cell';
import listCell from '../cells/list-cell';
import { booleanCell } from '../cells/boolean-cell';
import dayjs from 'dayjs';

const getContractLines = (params) => {
  return params.row.contract_lines;
};

export const StockQuantitiesColumns = (locked?: boolean): GridColumns => {
  const translationPrefix = 'entities.stock_quantity';
  const { t } = useTranslation();
  return [
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 200,
      filterOperators,
      sortable: !locked,
      filterable: !locked,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      width: 200,
      filterOperators,
      sortable: !locked,
      filterable: !locked,
      valueFormatter: ({ value }) => t(`entities.product.status_options.${value}`),
      renderCell: productStatusCell(t)
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      width: 200,
      filterOperators,
      sortable: !locked,
      filterable: !locked,
      renderCell: baseCell
    },
    {
      field: 'order_notes',
      headerName: t(`entities.product.order_notes`),
      width: 200,
      filterOperators,
      sortable: !locked,
      filterable: !locked,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 200,
      filterOperators,
      sortable: !locked,
      filterable: !locked,
      renderCell: baseCell
    },
    {
      field: 'category',
      headerName: t(`global.category`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'locations',
      headerName: t(`global.locations`),
      width: 200,
      renderCell: listCell,
      sortable: false,
      filterOperators
    },
    {
      field: 'suppliers',
      valueGetter: getContractLines,
      headerName: t(`${translationPrefix}.suppliers`),
      renderCell: (params: GridRenderCellParams) => {
        const suppliers = params.value.map(({ supplier }) => supplier);
        return <DataGridCell content={suppliers.join(',')} />;
      },
      valueFormatter: ({ value }) => {
        const values = value.map((value) => value.supplier);
        return values.join(', ');
      },
      filterOperators,
      sortable: !locked,
      filterable: !locked,
      width: 300
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      filterOperators: dateFilterOperators,
      valueFormatter: (params) => params.value && dayjs(params.value).format('DD/MM/YYYY'),
      editable: false,
      width: 200,
      type: 'date'
    },
    {
      field: 'discounted',
      headerName: t(`${translationPrefix}.discounted`),
      width: 200,
      renderCell: booleanCell(t)
    },
    {
      field: 'quantity',
      headerName: t(`${translationPrefix}.quantity`),
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => {
        const actions = [
          <GridActionsCellItem
            key={1}
            icon={<LocalPrintshopIcon />}
            onClick={() =>
              printLabels(
                params.row['product'],
                params.row['product_batch'],
                params.row['expiration_date'],
                params.row['quantity'],
                params.row['description']
              )
            }
            label={t('global.print_labels')}
          />,
          <GridActionsCellItem
            key={2}
            icon={<UnfoldLessIcon />}
            onClick={() =>
              printLabels(
                params.row['product'],
                params.row['product_batch'],
                params.row['expiration_date'],
                params.row['quantity'],
                params.row['description'],
                true
              )
            }
            label={t('global.print_barcode')}
          />
        ];
        return actions;
      }
    }
  ];
};
